.App {
  text-align: center;
}




.App-link {
  color: #61dafb;
}

.navbar-expand {
  justify-content: space-between !important;
  padding-left: 2rem !important;
  padding-right: 2rem !important;

}



.Nav-logo {
  width: 5rem;
}

.footer,
.push {
  height: 50px;
}

html, body {
  background-attachment: fixed;
    background-size: cover;
    height: 100%;
    overflow: auto;
  margin: 0;
  background: linear-gradient(137deg, rgba(255,255,255,1) 0%, rgba(219,236,241,1) 33%, rgba(193,225,233,1) 69%, rgba(167,209,188,1) 100%) !important;
  
}
.wrapper {
  min-height: 100%;
  padding-top: 3vh;
  height: 100%;
  margin-bottom: -50px;
}

.btn-light {
  border: 1px solid #cec8c8 !important;
}