

.container{
    width: 100%;
}
#dropdown {
    background-color: #F9F4F2;
    border-color: #F9F4F2;
    width: 100%;
    margin-bottom: 10px;
}

.dropdown-menu.show{
    width: 100%;
    margin: auto;

}
.dropdown-toggle::after {
    float: right;
    margin-top: 0.6em;
   
  }

.custom-file-upload {
    background-color: #F9F4F2;
    display: flex;
    border: 1px solid #a8a4a3;
    justify-content: center;
    border-radius: 0.375rem;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    height: 5em;
    width: 100%;
    text-align: center;
   
}

.form-check-input:checked {
    background-color: #696666 !important;
    border-color: #696666 !important;
}

hr {
    border: 0;
    clear:both;
    display:block;
    width: 96%;               
    background-color:#FFFF00;
    height: 1px;
  }

  .modal-footer{
    justify-content: center !important;
  }
 .modal-body{
    text-align: center !important;
 }
 .modal-header{
    justify-content: center !important;
 }
