.container {
    display: flex;
    flex-direction: column;
    /*background-color: lightblue;*/
    align-items: center;
   
}
.title-login {

    flex-direction: column;
    align-items: flex-end;
}


.App-logo {
    height: 10vmin;
    pointer-events: none;
  }

.body-home {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.button-container {
    margin: 1vh;
    width: 40vh;
}
