#categoryDropdown {
    width: 100%;
    background-color: #F9F4F2;
    border-color: white;
    max-width: 400px;
}

#dropdownDiv {
    width: 100%;
    max-width: 400px;
    align-items: center;
    margin: auto;
}

.dropdown {
    align-items: center;
}

.subCategory {
    margin-top: 20px;
    margin-left: 50px; 
}

.subCategoryTitle {
    width: 100%;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 20px;
    font-size: large;
    text-transform: uppercase;

}

.listOfPosts {
    width: 80%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 10;
    margin-left: 2rem;
}

.deleteButton {
    background-color: gray !important; 
    border-color: gray !important; 
    color: white !important; 
    margin-left: 10px; 
}

.updateButton {
    background-color: #1C4F5A !important;
    border-color: #1C4F5A !important; 
}

.card {
    background-color: #F9F4F2 !important;
}

#addTagButton {
    margin-top: 10px !important;
    background-color: #1C4F5A;
    border-color: #1C4F5A;
}

#tag-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 20rem;
    margin-top: 10px;
    padding-left: 0;
}

#tag-list-element {
    padding: 5px;
}

#tag-pill {
    height: 40px;
    width: auto;
   background-color: gray !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 20px;
}

#remove-tag {
    margin-top: 15px;
    margin-left: 5px;
    padding: 5px;
    font-size: 14px;
    cursor: pointer;
}